import React, { useEffect, useState } from 'react';
import { Parallax } from "react-parallax";
import Footer from "./Footer";
import Header from "./Header";
import image2 from "../Assets/page_bg.jpg";
import axios from 'axios';
import { Link } from "react-router-dom";
import CrackersLoader from './CrackersLoader';


function Terms() {
  const [termsContent, setTermsContent] = useState("");
  const [isLoading, setIsLoading] = useState(true); // State to manage loading


  useEffect(() => {
    const fetchTermsData = async () => {
      try {
        const response = await axios.get("https://www.sripoorvikaafireworks.com/customerapi/content.php");
        const { terms } = response.data.body;

        // Set termsContent as a string
        setTermsContent(terms);
      } catch (error) {
        console.error("Error fetching the terms content", error);
      }finally {
              setIsLoading(false); // Stop loading after data is fetched
            }
    };

    fetchTermsData();
  }, []);
  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }
  return (
    <div>
      <div className="main">
        <Header />

        {/* Parallax Section */}
        <Parallax
          bgImage={image2}
          bgImageAlt="Terms Background"
          strength={500}
          style={{ minHeight: "100px" }}
        >
          <div style={{ height: "230px" }}>
            <h1
              style={{
                color: "white",
                paddingTop: "100px",
                textAlign: "center",
              }}
            >
              Terms & Conditions
            </h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
               <Link to="/" style={{color:"white"}}>
               Home</Link> <i className="bi bi-chevron-right"></i> Terms & Conditions
            </p>
          </div>
        </Parallax>

        <div className='terms-content' style={{ padding: "20px" }}>
          {/* Render terms content */}
          <div dangerouslySetInnerHTML={{ __html: termsContent.value }} />
        </div>
      </div>
      <Footer />
      
      <div className="minifooter1">
      <div className="minimenu">
          
      <Link to="/">
            <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-house-door" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Home
             </div>
             </Link>


             <Link to="/about">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-journal-text officon" style={{marginLeft:"20px",fontSize:"1.4rem",color:"white"}}></i>
             About Us
             </div>
             </Link>
         
          
          <Link to="/quick">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-bag" style={{marginLeft:"25px",fontSize:"1.4rem"}}></i>
             Quick Buy
             </div>
             </Link>
          
            
             
          <Link to="/contact">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-telephone" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Contact
             </div>
             </Link>
          
        </div>
      </div>
    </div>
  );
}

export default Terms;
