import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { IoSendSharp } from "react-icons/io5";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./Footer";
import Header from "./Header";
import Scroll from "./Scroll";
import Card from "react-bootstrap/Card";
import image from "../Assets/wp3589952.webp";
import image1 from "../Assets/aboutus.jpg";
import image2 from "../Assets/safety.jpg";
import Stats from "./Stats";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CrackersLoader from './CrackersLoader';
import Animation from "./Animation"



const Home = () => {
  const [isChatBoxVisible, setChatBoxVisible] = useState(false);
  const [showDos, setShowDos] = useState(true);
  const [categories, setCategories] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [marqueeText, setMarqueeText] = useState([]);
  const [message, setMessage] = useState("");
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const toggleChatBox = () => {
    setChatBoxVisible(!isChatBoxVisible);
  };
  
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    if (!whatsappNumber) {
      console.log("WhatsApp number is not available yet");
      return;
    }

    const whatsappUrl = `https://wa.me/${whatsappNumber.replace(
      "+",
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://www.sripoorvikaafireworks.com/customerapi/home.php"
        );
        const { body } = response.data;
        setCategories(body?.category_front || []);
        setCarouselImages(body?.banner || []);
        setMarqueeText(body?.scrollnew?.map(item => item.msg) || []);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const response = await axios.get("https://www.sripoorvikaafireworks.com/customerapi/content.php");
        const { body } = response.data;
        const { whatsapp_number } = response.data.body.contact;
        setHomeData(body.home);
        setWhatsappNumber(whatsapp_number);
      } catch (error) {
        console.error("Error fetching the about data", error);
      }
    };
    fetchHomeData();
  }, []);

  // Fallback content if data is not loaded
  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }

  if (!homeData) {
    return <p>Loading....</p>; // Handle the case when homeData is not available yet
  }


  return (
    <div className="main-homepage">
      <Header />

      {/* Carousel Section */}
      <div
        className="carousel-container"
        style={{ position: "relative", overflow: "hidden" }}
      >
        <Carousel interval={3000}>
          {carouselImages.map(({ id, image }) => (
            <Carousel.Item key={id}>
              <img
                className="d-block w-100 "
                src={image}
                alt="Carousel slide"
                // onClick={() => handleImageClick(image)}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Animation />
      </div>

      {/* Marquee Section */}
      <div className="marquee-container">
        <div className="marquee">
          <p>{marqueeText}</p>
        </div>
      </div>

      {/*   content  Section */}

      <div
        className="content-section"
        style={{ backgroundImage: `url(${image})` }}
      >
        {/* Top Image */}
        <div className="topimg">
          <img src={image1} alt="" />
        </div>
        {/* Content aligned to the right */}
        <div className="content-text">
          <h5>Company Overview</h5>
          <br />
          <h3 style={{ letterSpacing: "2px", color: "darkblue" }}>
           SRI POORVIKAA FIREWORKS
          </h3>
          <h4>
            WHOLESALE & RETAIL SHOP IN{" "}
            <span style={{ color: "darkred" }}>SIVAKASI</span>
          </h4>
          <br />
          <p
              style={{ fontFamily: " Arial, Helvetica, sans-serif" }}
              dangerouslySetInnerHTML={{ __html: homeData.value }}
            />
          <br />
          <hr />
          <div className="adlist">
            <div className="adlist2">
              <p>
                <i class="bi bi-check tick"> </i>
                <span>  Best Quality</span>
              </p>
              <p>
                <i class="bi bi-check tick"></i>
                <span> Best price</span>
              </p>
              
            </div>
            <div className="adlist1">
              <p>
                <i class="bi bi-check tick"> </i>
                <span> Safe to Use</span>
                
              </p>
              {/* <p>
                <i class="bi bi-check tick"></i>
                <span> Our Crackers are Organic</span>
              </p> */}
              
            </div>
          </div>
        </div>
      </div>

      {/*categories section */}

      <div style={{ height: "auto" }}>
        <div>
          <div className="newarr">
            <div className="horizontal-line-text-container">
              <hr className="line" />
              <h4 className="text123">Our Top Categories </h4>
              <hr className="line" />
            </div>

            <div className="newarrival">
              {categories.map((category) => (
                <div key={category.category_id} className="card-container">
                  <Link to="/quick">
                  <Card
                    style={{ width: "14rem" }}
                    className="sub-banner custom-shadow "
                  >
                    <img
                      src={category.image}
                      alt={category.category_name}
                      className="d-block   img12"
                    />
                    <Card.Body>
                      <Card.Title className="imgname">
                        {category.category_name}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                  </Link>
                </div>
              ))}
            </div>

            <br />
          </div>
        </div>
      </div>

      {/* do's and dont's section  */}

      <div className="content-section1">
        {/* Top Image */}

        {/* Content aligned to the right */}
        <div className="content-text">
          
          <br />
          <h3 style={{ letterSpacing: "2px", color: "darkblue" }}>
            SAFETY TIPS
          </h3>
          <div className="dos">
            <Button
              as="input"
              type="button"
              value="DO'S"
              className="doss"
              onClick={() => setShowDos(true)}
            />
            <Button
              as="input"
              type="button"
              value="DON'TS"
              className="donts"
              onClick={() => setShowDos(false)}
            />
          </div>
          <br />
          {showDos ? (
            <div className="dos-content">
              <p >
                Here are some important safety tips you should follow when using
                our products:
              </p>
              <ul>
                <li>
                  If you have to buy fireworks, be sure to buy them from a
                  licensed dealer
                </li>
                <li>Keep a bucket of sand or a fire extinguisher handy.</li>
                <li>
                  Ensure there are no inflammable and combustible materials
                  around the fire-cracking area
                </li>
                <li>Follow all safety precautions issued with the fireworks</li>
                <li>Discard used fireworks in a bucket of water</li>
                <li>Burst crackers in safe places that are not crowded</li>
                {/* Add more dos here */}
              </ul>
            </div>
          ) : (
            <div className="donts-content">
              <p>
                Avoid these actions to ensure safety:
              </p>
              <ul>
                <li>Don't let children play alone with firecrackers</li>
                <li>
                  Don't keep the firecrackers near burning diyas or agarbattis.
                </li>
                <li>
                  Don't wear synthetic or loose garments; thick cotton clothes
                  are ideal.
                </li>
                <li>
                  Don't light crackers while holding them in your hand; you
                  should always light them in open grounds pointing straight up
                </li>
                {/* Add more don'ts here */}
              </ul>
            </div>
          )}
          {/* <br />
          <p style={{ fontFamily: "cursive" }}>
            We are "Poorvika Crackers," a leading Supplier and Retailer of
            crackers and fireworks based in Duraisamypuram, Sivakasi, Tamilnadu.
          </p>
          <p style={{ fontFamily: "cursive" }}>
            We provide you with the best and original crackers at a very
            reasonable price.
          </p>
          <br /> */}
        </div>
        <div className="topimg">
          <img src={image2} alt="Top" />
        </div>
      </div>
      
      <Stats />

      <div className="minifooter1">
      <div className="minimenu">
          
            <Link to="/">
            <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-house-door" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Home
             </div>
             </Link>
         
             <Link to="/about">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-journal-text officon" style={{marginLeft:"20px",fontSize:"1.4rem",color:"white"}}></i>
             About Us
             </div>
             </Link>


          <Link to="/quick">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-bag" style={{marginLeft:"25px",fontSize:"1.4rem"}}></i>
             Quick Buy
             </div>
             </Link>

             
         
          <Link to="/contact">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-telephone" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Contact
             </div>
             </Link>
          
        </div>
      </div>

      {/* Floating WhatsApp Icon */}
      {/* WhatsApp Icon */}
      <div className="floating-whatsapp-container">
        <div className="floating-whatsapp" onClick={toggleChatBox}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </div>
        {isChatBoxVisible && (
          <div className="chat-box">
            <div className="wats-header">
              <p>Sri Poorvikaa Fireworks</p>
              <button className="close-button" onClick={toggleChatBox}>
                X
              </button>
            </div>
            <div className="wats-content">
              <p>Welcome To Sri Poorvikaa Fireworks</p>
            </div>
            <div className="chat-content">
            <textarea
              placeholder="Type your message here..."
              value={message}
              onChange={handleMessageChange}
            ></textarea>
            <button onClick={sendMessage}>
              <IoSendSharp />
            </button>
          </div>
          </div>
        )}
      </div>
      <Scroll />
      <Footer />
    </div>
  );
};

export default Home;
