import React from 'react';
import { Parallax } from "react-parallax";
import Footer from "./Footer";
import Header from "./Header";
import image2 from "../Assets/page_bg.jpg";
import { Link } from "react-router-dom";

function Thanks() {
 
  return (
    <div>
      <div className="main">
        <Header />

        {/* Parallax Section */}
        <Parallax
          bgImage={image2}
          bgImageAlt="the cat"
          strength={500}
          style={{ minHeight: "100px" }}
        >
          <div style={{ height: "230px" }}>
            <h1
              style={{
                color: "white",
                paddingTop: "100px",
                textAlign: "center",
              }}
            >
              Thanks
            </h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
             <Link to="/" style={{color:"white"}}>
             Home</Link><i className="bi bi-chevron-right"></i> Thanks
            </p>
          </div>
        </Parallax>

        <div className='privacy-content' style={{ padding: "20px" }}>
          {/* Render privacy content */}
          {/* Check if privacyContent is a string before rendering */}
          
            <h3> Thanks For Your Enquiry. We Will Contact You Soon, Please Check Your Email.</h3>
          
           
        
        </div>

        
      </div>
      <Footer />
      
      <div className="minifooter1">
      <div className="minimenu">
          
      <Link to="/">
            <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-house-door" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Home
             </div>
             </Link>


             <Link to="/about">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-journal-text officon" style={{marginLeft:"20px",fontSize:"1.4rem",color:"white"}}></i>
             About Us
             </div>
             </Link>
         
          
          <Link to="/quick">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-bag" style={{marginLeft:"25px",fontSize:"1.4rem"}}></i>
             Quick Buy
             </div>
             </Link>
          
             
             
          <Link to="/contact">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-telephone" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Contact
             </div>
             </Link>
          
        </div>
      </div>
    </div>
    
  );
}

export default Thanks;
