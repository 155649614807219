import React, { useState, useEffect } from 'react';
import './CrackersLoader.css'; // Import the CSS for the loader animation
import Crakcergif from "../Assets/chinesenewyear-firecrackers.gif"

function CrackersLoader({ duration = 3000, onFinish }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (onFinish) {
        onFinish();  // Call onFinish callback if provided
      }
    }, duration);

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, [duration, onFinish]);

  if (!isLoading) {
    return null; // Hide loader after the timer ends
  }
  return (
    <div className="loader-container">
      <div className="crackers-animation">
        {/* Add your customized crackers animation here */}
        <img src={Crakcergif} alt="Loading..." className="crackers-gif" />
      </div>
      <p>Loading...</p>
    </div>
  );
}

export default CrackersLoader;
