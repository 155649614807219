import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Offcanvas } from "react-bootstrap";
import "./Header.css";
import Mainlogo from "../Assets/sripoorvikaa-logo.png";
import axios from 'axios';
import Button from 'react-bootstrap/Button';

function Header() {
  const [cartCount, setCartCount] = useState(0);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [priceListUrl, setPriceListUrl] = useState(""); // New state for price list URL

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get("https://www.sripoorvikaafireworks.com/customerapi/content.php");
        const { body } = response.data;
        
        setContactData(body.contact);
        setPriceListUrl(body.pricelist.pricelist); // Set price list URL from API response
      } catch (error) {
        console.error("Error fetching the contact data", error);
      }
    };

    fetchContactData();
  }, []);

  useEffect(() => {
    // Retrieve cart count from local storage
    const count = parseInt(localStorage.getItem("cartCount")) || 0;
    setCartCount(count);

    // Optionally, listen for changes in local storage (if using multiple tabs)
    const handleStorageChange = () => {
      const count = parseInt(localStorage.getItem("cartCount")) || 0;
      setCartCount(count);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  const handleDownloadPriceList = () => {
    if (priceListUrl) {
      window.open(priceListUrl, "_blank");
    }
  };

  return (
    <div className="nav-main">
      <div id="header" className="header sticky-top">
        <div className="topbar d-flex align-items-center">
          <div className="container d-flex justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope">
                <span>{contactData?.email || ""}</span>
              </i>
              <i className="bi bi-phone">
                <span>{contactData?.phonenumber || ""}</span>
              </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <Link to="/" className="social">
                <i className="bi bi-facebook"></i>
              </Link>
              <Link to="/" className="social">
                <i className="bi bi-twitter"></i>
              </Link>
              <Link to="/" className="social">
                <i className="bi bi-youtube"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="branding d-flex align-items-center">
          <div className="container position-relative d-flex align-items-center justify-content-between">
            <Link to="/" className="logo d-flex align-items-center me-auto">
              <div className="logo d-flex align-items-center me-auto">
                <img src={Mainlogo} alt="Logo" />
              </div>
            </Link>

            <nav id="navmenu" className="navmenu d-none d-xl-block">
              <ul>
                <li>
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/quick" className="nav-link">
                    Quick Purchase
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>

            <Button className="cta-btn d-none d-sm-block blink" onClick={handleDownloadPriceList}>
              Download Price List
            </Button>
          </div>
          <div className="cart">
            <Link to="/cart">
              <FontAwesomeIcon icon={faShoppingCart} />
              {cartCount > 0 && <span className="badge">{cartCount}</span>}
            </Link>
          </div>
          <i
            className="mobile-nav-toggle bi bi-list"
            onClick={handleShow}
          ></i>
        </div>
      </div>

      {/* Offcanvas Menu */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={Mainlogo} alt="Logo" style={{ width: "150px" }} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="offcanvas-menu">
            <li>
              <Link to="/" className="nav-link" onClick={handleClose}>
                <i className="bi bi-house-fill officon"></i> Home
              </Link>
            </li>
            <hr />
            <li>
              <Link to="/about" className="nav-link" onClick={handleClose}>
                <i className="bi bi-journal-text officon"></i> About Us
              </Link>
            </li>
            <hr />
            <li>
              <Link to="/quick" className="nav-link" onClick={handleClose}>
                <i className="bi bi-bag-fill officon"></i> Quick Purchase
              </Link>
            </li>
            <hr />
            <li>
              <Link to="/contact" className="nav-link" onClick={handleClose}>
                <i className="bi bi-telephone-fill officon"></i> Contact Us
              </Link>
            </li>
            <hr />
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Header;
