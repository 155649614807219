import React, { useEffect, useRef } from 'react';

const Animation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let w, h;
    const particles = [];
    const probability = 0.04;
    let xPoint, yPoint;

    const resizeCanvas = () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
    };

    const updateWorld = () => {
      update();
      paint();
      requestAnimationFrame(updateWorld);
    };

    const update = () => {
      if (particles.length < 500 && Math.random() < probability) {
        createFirework();
      }
      const alive = [];
      for (let i = 0; i < particles.length; i++) {
        if (particles[i].move()) {
          alive.push(particles[i]);
        }
      }
      particles.length = 0;
      particles.push(...alive);
    };

    const paint = () => {
      ctx.clearRect(0, 0, w, h); // Clear canvas with transparent background
      ctx.globalCompositeOperation = 'lighter';
      for (const particle of particles) {
        particle.draw(ctx);
      }
    };

    const createFirework = () => {
      xPoint = Math.random() * (w - 200) + 100;
      yPoint = Math.random() * (h - 200) + 100;
      const nFire = Math.random() * 50 + 100;
      
      // Generate a color based on position
      const red = Math.floor((xPoint / w) * 255);
      const green = Math.floor((yPoint / h) * 255);
      const darkblue = Math.floor((Math.random() * 255));
      const orange = Math.floor((Math.random() * 255));
      
      const c = `rgb(${red}, ${green}, ${darkblue},${orange})`;
      
      for (let i = 0; i < nFire; i++) {
        const particle = new Particle();
        particle.color = c;
        const vy = Math.sqrt(25 - particle.vx * particle.vx);
        if (Math.abs(particle.vy) > vy) {
          particle.vy = particle.vy > 0 ? vy : -vy;
        }
        particles.push(particle);
      }
    };

    function Particle() {
      this.w = this.h = Math.random() * 4 + 1;
      this.x = xPoint - this.w / 2;
      this.y = yPoint - this.h / 2;
      this.vx = (Math.random() - 0.5) * 10;
      this.vy = (Math.random() - 0.5) * 10;
      this.alpha = Math.random() * 0.5 + 0.5;
      this.color = '';
    }

    Particle.prototype = {
      gravity: 0.05,
      move: function () {
        this.x += this.vx;
        this.vy += this.gravity;
        this.y += this.vy;
        this.alpha -= 0.01;
        if (this.x <= -this.w || this.x >= w ||
          this.y >= h ||
          this.alpha <= 0) {
            return false;
        }
        return true;
      },
      draw: function (c) {
        c.save();
        c.beginPath();
        c.translate(this.x + this.w / 2, this.y + this.h / 2);
        c.arc(0, 0, this.w, 0, Math.PI * 2);
        c.fillStyle = this.color;
        c.globalAlpha = this.alpha;
        c.closePath();
        c.fill();
        c.restore();
      }
    };

    const init = () => {
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
      requestAnimationFrame(updateWorld);
    };

    init();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, pointerEvents: 'none' }} />;
};

export default Animation;
