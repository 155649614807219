import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./Contact.css";
import Footer from "./Footer";
import Header from "./Header";
import { Parallax } from "react-parallax";
import image2 from "../Assets/page_bg.jpg";
import { Link } from "react-router-dom";
import CrackersLoader from './CrackersLoader';


function Contact() {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get("https://www.sripoorvikaafireworks.com/customerapi/content.php");
        const { body } = response.data;
        setContactData(body.contact);
      } catch (error) {
        setError("Error fetching contact data.");
        console.error("Error fetching the contact data", error);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (isLoading) {
    return <CrackersLoader />; // Show the custom loader while fetching data
  }

  return (
    <div className="main-homepage">
      <Header />
      <Parallax
        bgImage={image2}
        bgImageAlt="the cat"
        strength={500}
        style={{ minHeight: "100px" }}
      >
        <div style={{ height: "230px" }}>
          <h1
            style={{
              color: "white",
              paddingTop: "100px",
              textAlign: "center",
            }}
          >
            Contact
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
             <Link to="/" style={{color:"white"}}>
             Home</Link> <i className="bi bi-chevron-right"></i> Contact Us
          </p>
        </div>
      </Parallax>

      <div id="contact" className="contact section">
        <h2 className='main-head'>Have Any Questions?</h2><br />
        <div className="container section-title" data-aos="fade-up">
          <h3 className='main-head1'>Contact Us</h3>
        </div>
        <div className="row gy-4">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="col-lg-4">
                <div
                  className="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-geo-alt sticon1"></i>
                  <h3>Address</h3>
                  <p>{contactData?.address}</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div
                  className="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-telephone sticon1"></i>
                  <h3>Call Us</h3>
                  <p>{contactData?.phonenumber}</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div
                  className="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-envelope sticon1"></i>
                  <h3>Email Us</h3>
                  <p>
                    <a
                      href="/cdn-cgi/l/email-protection"
                      className="__cf_email__"
                      data-cfemail="cea7a0a8a18eabb6afa3bea2abe0ada1a3"
                    >
                      {contactData?.email}
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row gy-4 mt-1">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
          <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.305624647098!2d77.7205792!3d9.4331164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06c53703267ed3%3A0x2e45493851558dab!2sPoorvikaa%20Fireworks%20Factory%20Outlet!5e0!3m2!1sen!2sin!4v1693185804323!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ border: "0", width: "100%", height: "400px" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Poorvikaa Fireworks Factory Outlet"
               />

          </div>

          <div className="col-lg-6">
            <form
              action="forms/contact.php"
              method="post"
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    required
                  />
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    placeholder="Subject"
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    name="message"
                    className="form-control"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <div className="minifooter1">
      <div className="minimenu">
          
      <Link to="/">
            <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-house-door" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Home
             </div>
             </Link>
         

             <Link to="/about">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-journal-text officon" style={{marginLeft:"20px",fontSize:"1.4rem",color:"white"}}></i>
             About Us
             </div>
             </Link>
             
          
          <Link to="/quick">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-bag" style={{marginLeft:"25px",fontSize:"1.4rem"}}></i>
             Quick Buy
             </div>
             </Link>
          
            
             
          <Link to="/contact">
          <div style={{display:"flex", flexDirection:"column",color:"white"}}>
          <i class="bi bi-telephone" style={{marginLeft:"12px",fontSize:"1.4rem"}}></i>
             Contact
             </div>
             </Link>
          
        </div>
      </div>
    </div>
  );
}

export default Contact;
