import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Quick from './Pages/Quick';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Cart from './Pages/Cart';
import Terms from './Pages/Terms';
import ShippingPolicy from './Pages/ShippingPolicy';
import Thanks from './Pages/Thanks';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path='/quick' element={<Quick />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/shippingpolicy' element={<ShippingPolicy />} />
      <Route path='/thanks' element={<Thanks />} />
      </Routes>
    </Router>
  );
}

export default App;
